<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <!-- <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Secusy
      </h2> -->
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">No Workspace Found🕵🏻‍♀️</h2>
        <p class="mb-2">
          You might have no access to the TPRM module or this may not be activated for any of your workspaces. Please enable it to access this portal.
        </p>

        <b-button variant="primary" class="mb-2 btn-sm-block" @click="logout">
          Logout
        </b-button><br>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("app/logout")
      if (this.$route.name != "login") this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
